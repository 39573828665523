import ShopProductListBase from './components/ProductList/ProductListBase';
import ShopProductListItemBase from './components/ProductListItem/ProductListItemBase';
import ShopCategoryListBase from './components/CategoryList/CategoryListBase';
import ShopCategoryListItemBase from './components/CategoryListItem/CategoryListItemBase';
import ShopProductPrice from './components/ProductPrice/ProductPrice';
import ShopFilterPanelBase from './components/FilterPanel/FilterPanelBase';
import ShoppingCartBase from './components/ShoppingCart/ShoppingCartBase';
import ShopAddToCartBase from './components/AddToCart/AddToCartBase'
import ShopStockStatus from './components/StockStatus/StockStatus'
import ShopProductGallery from './components/ProductGallery/ProductGallery'

import routes from './routes';
import store from './store/';
import ShopView from '@/Modules/Shop/views/ShopView';

const ShopModule = {
  path: 'shop',
  name: 'Shop',
  enabled: true,
  store: store,
  children: routes,
  component: ShopView,
  registerComponents: (Vue) => {
    Vue.component('ShopProductListBase', ShopProductListBase);
    Vue.component('ShopProductListItemBase', ShopProductListItemBase);
    Vue.component('ShopCategoryListBase', ShopCategoryListBase);
    Vue.component('ShopCategoryListItemBase', ShopCategoryListItemBase);
    Vue.component('ShopProductPrice', ShopProductPrice);
    Vue.component('ShopFilterPanelBase', ShopFilterPanelBase);
    Vue.component('ShoppingCartBase', ShoppingCartBase);
    Vue.component('ShopAddToCartBase', ShopAddToCartBase);
    Vue.component('ShopStockStatus', ShopStockStatus);
    Vue.component('ShopProductGallery', ShopProductGallery);
  }
};

export default ShopModule;
