<template>
  <div class="sidebar-category-list-item-container">
    <router-link
        :to="Url"
        class="category-link"
        :class="{ 'no-products' : item.productCount === 0 }">
      <el-icon
          name="arrow-right"
          class="list-item-icon" /> {{ item.title }}
      <span
          v-if="item.productCount > 0"
          class="product-count">{{ item.productCount }}</span>
    </router-link>
    <div class="sub-category">
      <ShopCategoryListBase
          :items="FilteredCategoryList"
          variant="Sidebar"
          category-list-item-variant="Sidebar" />
    </div>
  </div>
</template>

<script>
import slugify from "slugify"

export default {
  name: "SidebarCategoryListItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    Url() {
      return slugify(`${this.item.title}`)
    },
    Categories() {
      return this.$store.getters["Shop/getCategories"]
    },
    FilteredCategoryList() {
      return this.Categories.filter(category => parseInt(category.parentId) === parseInt(this.item.id))
    },
  }
};
</script>

<style lang="scss" scoped>
.sidebar-category-list-item-container {
  font-weight: 600;

  .list-item-icon {
    display: none;
  }

  .sub-category {
    margin-left: var(--margin-m);
    margin-top: var(--margin-s);

    .list-item-icon {
      display: inline-block;
    }

    .category-link {
      font-weight: 400;

      &.no-products {
        opacity: 0.5;
      }
    }
  }

  .category-link {
    display: block;
    width: 100%;
    background: transparent;
    margin: 1px 0;
    padding: var(--padding-xs) var(--padding-m);
    box-sizing: border-box;
    font-size: var(--font-size-l);
    border: 1px solid transparent;
    transition: var(--main-transition);

    @media (max-width: 475px) {
      font-size: var(--font-size-m);
    }

    &:hover {
      color: var(--color-primary);
      background: rgba(0,0,0,0.04);
    }

    &.router-link-exact-active {
      font-weight: 600;
      color: black;
      background: rgba(255,255,255,0.4);
      border-radius: var(--border-radius-s);
    }

    .product-count {
      float: right;
      font-size: var(--font-size-m);
      font-weight: 400;
      margin-top: 2px;
      opacity: 0.5;
    }
  }
}
</style>