<template>
  <el-skeleton
      animated
      class="skeleton-product-list-item">
    <template slot="template">
      <div class="flex-container">
        <el-skeleton-item
            class="image-container"
            variant="image" />
        <div class="text-container">
          <el-skeleton-item
              variant="text" />
          <el-skeleton-item
              variant="text" />
          <el-skeleton-item
              variant="text" />
          <el-skeleton-item
              variant="text" />
        </div>
      </div>
      <div class="skeleton-footer">
        <el-skeleton-item
            variant="p" />
        <div
            class="flex-container-bottom">
          <el-skeleton-item
              variant="text" />
          <el-skeleton-item
              variant="text"
              class="short" />
        </div>
      </div>
    </template>
  </el-skeleton>
</template>

<script>
export default {
  name: "SkeletonProductListItem"
};
</script>

<style lang="scss" scoped>
.skeleton-product-list-item {
  flex: 1;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: var(--border-radius-s);
  transition: var(--main-transition);
  flex-basis: 30%;
  padding: var(--padding-m);
  max-width: 320px;

  .flex-container {
    display: flex;
    gap: 20px;
    .image-container {
      flex: 3;
      width: 120px;
      height: 120px;
    }
    .text-container {
      flex: 2;

      div:first-child {
        width: 50%;
      }
      div:nth-child(2) {
        width: 80%;
      }
      div:nth-child(3) {
        width: 100%;
      }
      div:nth-child(4) {
        width: 100%;
      }
    }
  }
  .skeleton-footer {
    padding: var(--padding-m) 0;
    .flex-container-bottom {
      display: flex;
      gap: 20px;

      .short {
        width: 30%;
      }
    }
  }
}
</style>