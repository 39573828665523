<template>
  <div
      class="product-details-container">
    <component
        :is="renderComponent"
        v-bind="$props"
        @close="handleClose" />
  </div>
</template>
<script>
import DefaultProductDetails from "./variants/DefaultProductDetails";
import FloatingProductDetails from "@/Modules/Shop/components/ProductDetails/variants/FloatingProductDetails";
import {camelCase, startCase } from "lodash"
export default {
  name: "ProductDetailsBase",
  components: {
    DefaultProductDetails,
    FloatingProductDetails
  },
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    position: {
      type: Object,
      default: () => {}
    },
    item: {
      type: Object,
      default: () => {}
    },
    quantity: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    renderComponent() {
      if (this.variant === 'default') {
        return 'DefaultProductDetails';
      }
      return startCase(camelCase(`${this.variant}ProductDetails`)).replace(/ /g, '');
    }
  },
  methods: {
    handleClose() {
      this.$emit("close")
    }
  }
};
</script>

<style scoped>

</style>