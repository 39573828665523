<template>
  <div class="stock-status-container">
    <div
        class="stock-status"
        :class="StockStatus.class">
      {{ StockStatus.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "StockStatus",
  props: {
    stockStatus: {
      type: [Object, Array, Number, String],
      required: true
    },
    extended: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    StockStatus() {
      let stockStatus = {
        class: "out-of-stock",
        title: "out of stock!"
      }

      if (this.stockStatus) {
        if (this.stockStatus > 0
          && this.stockStatus <= 4) {
          stockStatus.class = "low-stock"
          stockStatus.title = "low stock"
        } else if (this.stockStatus > 4) {
          stockStatus.class = "in-stock"
          stockStatus.title = "in stock"
          if (this.extended) {
            stockStatus.title += ` and ready to ship from our Brisbane Warehouse`
          }
        }
      }

      return stockStatus
    }
  },
};
</script>

<style lang="scss" scoped>
.stock-status-container {
  .stock-status {
    font-size: 12px;
    border-radius: var(--border-radius-xs);
    background-color: var(--color-primary-background);
    padding: var(--padding-xs) var(--padding-s);
    display: inline-block;
    font-weight: 600;

    &.in-stock {
      color: var(--color-special-success);
      background: white;
    }

    &.low-stock {
      color: var(--color-danger-text);
      background: var(--color-danger-background);
    }

    &.out-of-stock {
      color: var(--color-error-background);
      background: white;
    }
  }
}
</style>