<template>
  <div
      class="floating-product-details-container"
      :style="`left: ${position.left}px;bottom: 100px;`">
    <div class="close-container">
      <a
          class="close"
          @click="handleClose">
        <span class="el-icon-close" />
      </a>
    </div>
    <h5>{{ Title }} </h5>

    <div class="product-details-container">
      <div class="product-container__inner">
        <div class="image-container">
          <CoreImageBase
              :image-src="ImageSrc"
              alt="`Image of ${Title}`" />
        </div>
        <div class="details-container">
          <dl>
            <dt>Category</dt>
            <dd>{{ Category }}</dd>
          </dl>

          <dl>
            <dt>SKU</dt>
            <dd>{{ item.SKU }}</dd>
          </dl>

          <dl>
            <dt>Stock Status</dt>
            <dd>
              <StockStatus
                  class="stock-status"
                  :stock-status="WarehouseQuantity" />
            </dd>
          </dl>

          <dl>
            <dt>Quantity</dt>
            <dd>
              <el-input-number
                  id="product-quantity"
                  v-model="Quantity"
                  class="product-quantity-element"
                  size="mini"
                  controls-position="right"
                  :min="1"
                  :max="100" />
            </dd>
          </dl>
          <dl>
            <dt>Product Price</dt>
            <dd>
              <ShopProductPrice
                  :special="IsSpecial"
                  :sell-price="DefaultPrice"
                  :rrp-price="RRPPrice" />
            </dd>
          </dl>
        </div>
      </div>
      <div class="mobile-close-container">
        <button
            class="mobile-close"
            @click="handleClose">
          <span class="el-icon-close" /> Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import StockStatus from "@/Modules/Shop/components/StockStatus/StockStatus";
export default {
  name: "FloatingProductDetails",
  components: {
    StockStatus
  },
  props: {
    position: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      default: () => {}
    },
    quantity: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    Quantity: {
      get() {
        return this.quantity
      },
      set(val) {
        this.$store.dispatch("Shop/updateShoppingCartQuantity", { itemId: this.item.id, quantity: val})
      }
    },
    ImageSrc() {
      if (this.item.ImageList?.length > 0) {
        return this.$store.getters["Shop/getImageSrc"]({image: this.item.ImageList[1], width: 344, height: 344})
      }
      return "https://via.placeholder.com/150"
    },
    Title() {
      return this.item.Name
    },
    Category() {
      if (this.item.ItemSpecifics.length && this.item.ItemSpecifics[0].ItemSpecific.length) {
        const carModel =  this.item.ItemSpecifics[0].ItemSpecific.find(item => item.Name === "Car Model")
        if (carModel) {
          return carModel.Value
        }
      } else {
        if (this.item.Categories?.length > 0) {
          return this.item.Categories[0].Category[0].CategoryName
        }
      }
      return false
    },
    IsSpecial() {
      return this.item.DefaultPrice < parseFloat(this.item.RRPPRice)
    },
    DefaultPrice() {
      if (this.item.DefaultPrice) {
        return this.item.DefaultPrice
      }
      return 'N/A'
    },
    RRPPrice() {
      if (this.item.RRPPrice) {
        if (!isNaN(this.DefaultPrice) && this.DefaultPrice < this.item.RRPPrice) {
          return this.item.RRPPrice
        }
      }
      return null
    },
    WarehouseQuantity() {
      return this.$store.getters['Shop/getProductQuantity'](this.item.id)
    },
  },
  methods: {
    handleClose() {
      this.$emit("close")
    }
  }
};
</script>

<style lang="scss" scoped>
.floating-product-details-container {
  position: absolute;
  bottom: 100px;
  background: white;
  z-index: 1;
  width: 300px;
  border-radius: var(--border-radius-m);
  padding: var(--padding-xl);
  box-shadow: var(--box-shadow-shortest);
  transition: var(--main-transition);

  .mobile-close-container {
    display: none;
  }

  @media(max-width: 475px) {
    width: 100vw;
    height: 90vh;
    left: 0 !important;
    bottom: 0 !important;

    .mobile-close-container {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        color: white;
      }

    }
  }

  //add comment bubble to the bottom left of the product details
  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 30px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }

  .close-container {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  h5 {
    margin: var(--margin-m) 0;
    color: rgba(0,0,0,0.8);
    line-height: 1.4;
  }

  .product-category {
    font-size: var(--font-size-m);
    padding: var(--padding-s) 0;
    color: rgba(0,0,0,0.6);
  }

  ::v-deep .stock-status {
    padding: 0;
  }

  .details-container {
    dl {
      display: flex;
      justify-content: space-between;
      font-size: var(--font-size-m);
      padding: var(--padding-s) 0;
      align-items: center;

      dt {
        font-weight: 500;
        flex: 1;
      }
      dd {
        flex: 3;
        text-align: right;

      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0,0,0,0.1);
      }
    }
  }

  ::v-deep .image-container {
    min-width: 200px;
    max-width: 200px;
    height: 200px;
    width: 200px;
    padding: 1px;
    overflow: hidden;
    margin: auto;

    img {
      width: 202px;
      height: 202px;
      margin-left: -2px;
      margin-top: -2px;
    }
  }
}
</style>