<template>
  <div
      v-if="Subtotal"
      class="shopping-cart-total-container">
    <div class="subtotal-container">
      <div class="title">
        <h5>Subtotal</h5>
      </div>
      <div class="price-container subtotal">
        <ProductPrice
            :rrp-price="Subtotal"
            :sell-price="Subtotal"
            :mark-discount="false" />
      </div>
    </div>

    <div
        v-if="Discount"
        class="discount-container">
      <div class="title">
        <h5>Discount (your savings)</h5>
      </div>
      <div class="price-container discount">
        <ProductPrice
            :rrp-price="Discount" />
      </div>
    </div>

    <div
        v-if="ShippingSelected"
        v-loading="IsUpdatingShippingQuote"
        class="discount-container"
        element-loading-text="Updating Shipping Fees..."
        element-loading-background="rgba(255,255,255, 0.95)"
        element-loading-spinner="el-icon-loading">
      <div class="title">
        <h5>Shipping Cost</h5>
      </div>
      <div

          class="price-container shipping-cost">
        <ProductPrice
            v-if="!IsUpdatingShippingQuote && ShippingQuote"
            :sell-price="parseFloat(ShippingQuote.ShippingCost)" />
      </div>
    </div>

    <div class="total-container">
      <div class="title">
        <h4>Total</h4>
      </div>
      <div class="price-container">
        <ProductPrice
            :sell-price="Total" />
      </div>
    </div>

    <div
        v-if="showCheckoutButton"
        class="checkout-container">
      <button
          type="button"
          class="checkout"
          @click="handleCheckout">
        Checkout
      </button>
    </div>
  </div>
</template>

<script>
import ProductPrice from "@/Modules/Shop/components/ProductPrice/ProductPrice";
export default {
  name: "ShoppingCartTotal",
  components: { ProductPrice },
  props: {
    shoppingCartItems: {
      type: Array,
      required: true
    },
    showCheckoutButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    IsUpdatingShippingQuote() {
      return this.$store.state.Shop.isUpdatingShippingQuote
    },
    Subtotal() {
      let subtotal = 0;

      // Add shipping prices here

      this.shoppingCartItems.forEach((item) => {
          if (parseFloat(item.productDetails.RRP) > parseFloat(item.productDetails.DefaultPrice)) {
            subtotal = subtotal + (parseFloat(item.productDetails.RRP) * item.quantity)
          } else {
            subtotal = subtotal + (parseFloat(item.productDetails.DefaultPrice) * item.quantity)
          }
        }
      )
      if (subtotal <= 0) {
        subtotal = this.Total
      }
      return subtotal
    },
    ShippingSelected() {
      return this.$store.state.Shop.checkout?.orderDetails?.shipping?.includes("ship_to")
    },
    ShippingQuote() {
      if (this.$store.state.Shop.selectedShippingQuote?.ShippingCost) {
        return this.$store.state.Shop.selectedShippingQuote
      }
      return null
    },
    Total() {
      let total = 0;

      this.shoppingCartItems.forEach((item) => {
          total = total + (parseFloat(item.productDetails.DefaultPrice) * item.quantity)
        }
      )

      if (this.ShippingQuote?.ShippingCost) {
        total = total + parseFloat(this.ShippingQuote.ShippingCost)
      }

      return total
    },
    Discount() {
      const discount = this.Subtotal - this.Total

      if (discount > 0) {
        return discount
      }
      return false
    }
  },
  methods: {
    handleCheckout() {
      this.$store.dispatch("Shop/updateShowCart", false)
      this.$router.push("/shop/checkout");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "ShoppingCartTotal";
</style>