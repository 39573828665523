const routes = [
  {
    path: '/shop/checkout',
    name: 'checkout-view',
    component: () => import(/* webpackChunkName: "shop-checkout" */ './views/CheckoutView/CheckoutView')
  },
  {
    path: '/shop/:category?',
    name: 'product-listing-view',
    component: () => import(/* webpackChunkName: "shop-product-listing" */ './views/ProductListingView/ProductListingView')
  },
  {
    path: '/product/:productTitle',
    name: 'product-details-view',
    component: () => import(/* webpackChunkName: "shop-product-details" */ './views/ProductDetailsView/ProductDetailsView')
  },
];

export default routes;
