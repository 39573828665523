const bankTransferDetails = {
  bankName: 'Commonwealth Bank',
  accountName: 'MY COUSIN PTY. LTD',
  bsb: '064-184',
  accountNumber: '1085-8809'
}

const apiSettings ={
  api: 'dev.api.canetoadequip.com.au',
  imageApiPath: 'dev.api.canetoadequip.com.au/pic/',
  paypalApiClientId: {
    production: 'AUwDfDzllQkMneCPQjz0yPD4zgF3xYsvs_Twos_tk4Djpwbjjzodd7au_UZaRGYcZOM_NC9Y07RI2eNl',
    sandbox: 'AZz_5Vq1-KbpWrUsZx6ZH9vFpPUoU4DGsprX5ZS_XLrdYpOjmR7NazXhJWU_FCF8gRr7fEUumvZ7IJi7'
  },
}

export  {
  apiSettings,
  bankTransferDetails
}