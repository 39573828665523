const mutations = {
  UPDATE_FILTERS(state, payload) {
    state.filters = payload;
  },
  UPDATE_PRODUCTS(state, payload) {
    state.products = payload;
  },
  UPDATE_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  UPDATE_SHOPPING_CART(state, payload) {
    state.shoppingCart = payload;
    localStorage.setItem('shoppingCart', JSON.stringify(state.shoppingCart));
  },
  UPDATE_SHOW_CART(state, payload) {
    state.showCart = payload;
  },
  UPDATE_PROTOCOL(state, payload) {
    state.protocol = payload
  },
  UPDATE_IS_LOADING_ALL_DATA(state, payload) {
    state.isLoadingAllData = payload
  },
  UPDATE_CHECKOUT_DETAILS(state, payload) {
    state.checkout = payload
  },
  UPDATE_SHIPPING_QUOTES(state, payload) {
    state.shippingQuotes = payload
  },
  UPDATE_IS_UPDATING_SHIPPING_QUOTE(state, payload) {
    state.isUpdatingShippingQuote = payload
  },
  UPDATE_SELECTED_SHIPPING_QUOTE(state, payload) {
    state.selectedShippingQuote = payload
  },
  UPDATE_BILLING_ADDRESS(state, payload) {
    state.checkout.billingAddress = payload
  },
  UPDATE_SHIPPING_ADDRESS(state, payload) {
    state.checkout.shippingAddress = payload
  },
  UPDATE_SELECTED_PAYMENT(state, payload) {
    state.checkout.orderDetails.payment = payload
  },
  UPDATE_ORDER_STATUS(state, payload) {
    state.checkout.orderDetails.status = payload
  },
  UPDATE_ORDER_ID(state, payload) {
    state.checkout.orderDetails.orderId = payload
  },
  UPDATE_ORDER_TOTAL(state, payload) {
    state.checkout.orderDetails.total = payload
  },
  UPDATE_VERIFIED_ORDER_DETAILS(state, payload) {
    state.checkout.orderDetails.verified = payload
  }
};

export default mutations