<template>
  <div class="shopping-cart-product-list-item-container">
    <Portal
        v-if="showProductDetails"
        to="floating-product-details">
      <transition name="slide-down">
        <ProductDetailsBase
            variant="floating"
            :position="productDetailsPosition"
            :item="item"
            :quantity="quantity"
            @close="handleClose" />
      </transition>
    </Portal>
    <div class="product-container__outer">
      <div class="product-container__inner">
        <div @click="handleShowProductDetails">
          <div class="image-wrapper">
            <CoreImageBase
                class="product-thumbnail"
                :image-src="ImageSrc"
                :alt="`Image of ${Title}`" />
            <div
                class="quantity-container"
                title="Product Quantity">
              {{ quantity }}
            </div>
          </div>
        </div>
        <div class="delete-item-container">
          <span
              class="delete-item"
              @click="deleteItem"><i class="el-icon-close" /></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slugify from "slugify";
import ProductDetailsBase from "@/Modules/Shop/components/ProductDetails/ProductDetailsBase";
export default {
  name: "ShoppingCartProductListItem",
  components: {
    ProductDetailsBase
  },
  props: {
    itemIndex: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true
    },
    quantity: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
    showProductDetails: false,
      productDetailsPosition: {
        left: 0,
        bottom: 0,
      }
    }
  },
  computed: {
    ProductUrl() {
      return slugify(`${this.item.Name}-pid${this.item.id}`)
    },
    ProductDetailsLink(){
      return `/product/${this.ProductUrl}`
    },
    ImageSrc() {
      if (this.item.ImageList?.length > 0) {
        return this.$store.getters["Shop/getImageSrc"]({image: this.item.ImageList[1], width: 100, height: 100})
      }
      return "https://via.placeholder.com/150"
    },
    Title() {
      return this.item.Name
    },
    Category() {
      if (this.item.Categories?.length > 0) {
        return this.item.Categories[0].Category.CategoryName
      }
      return false
    },
    DefaultPrice() {
      if (this.item.DefaultPrice) {
        return this.item.DefaultPrice
      }
      return 'N/A'
    },
    Quantity: {
      get() {
        return this.quantity
      },
      set(val) {
        this.$store.dispatch("Shop/updateShoppingCartQuantity", { itemId: this.item.id, quantity: val})
      }
    },
    StockStatus() {
      return {
        class: "in-stock",
        title: "in stock"
      }
    },
    SubtotalDefaultPrice() {
      return this.item.DefaultPrice * this.quantity
    },
    SubtotalRRPPrice() {
      return this.RRPPrice * this.quantity
    },
    RRPPrice() {
      if (this.item.RRP) {
        if (!isNaN(this.DefaultPrice) && this.DefaultPrice < this.item.RRP) {
          return this.item.RRP
        }
      }
      return null
    }
  },
  methods: {
    handleClose() {
      this.showProductDetails = false;
    },
    handleShowProductDetails(event) {
      const rect = event.target.getBoundingClientRect();
      this.productDetailsPosition = {
        left: parseInt(rect.left),
        top: parseInt(rect.top),
      }

      this.showProductDetails = !this.showProductDetails
    },
    deleteItem() {
      this.$store.dispatch("Shop/removeItemFromCart", this.itemIndex)
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'FloatingShoppingCartProductListItem.scss';
</style>