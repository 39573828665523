<template>
  <div class="vertical-filter-panel">
    <div
        v-for="(filter, index) in filters"
        :key="`filter-${index}`">
      <h3 v-if="filter.title">
        {{ filter.title }}
      </h3>
      <CoreFilterBase
          :variant="filter.variant"
          :type="filter.type"
          :filter-props="filter.props"
          v-on="$listeners" />
    </div>
  </div>
</template> 

  <script>
export default {
  name: "VerticalFilterPanel",
  props: {
    filters: {
      type: Array,
      required: true
    }
  },

};
</script>

<style lang="scss" scoped>
.vertical-filter-panel {
  background: transparent;
  padding: var(--padding-l) 0 var(--padding-l) 0;
  border-radius: var(--border-radius-s);
  position: relative;
  margin-bottom: var(--margin-xl);

  h3 {
    font-weight: 600;
    font-size: var(--font-size-xl);
    margin: var(--margin-s);
    position: absolute;
    top: -29px;
    padding: var(--padding-m);
    text-shadow: -1px 1px 0px #f8f7f3;
    left: -10px;
    color: var(--color-primary);
  }
}
</style>