<template>
  <div class="list-items-shopping-cart-container">
    <template v-if="ShoppingCartItems.length">
      <transition-group name="list">
        <ShopProductListItemBase
            v-for="(product, index) in ShoppingCartItems"
            :key="`shopping-cart-item-${index}`"
            variant="shopping-cart"
            :quantity="product.quantity"
            :item-index="index"
            :item="product.productDetails" />
      </transition-group>

      <ShoppingCartTotal :shopping-cart-items="ShoppingCartItems" />
    </template>
    <template v-else>
      <div class="empty-shopping-cart-wrapper">
        <el-empty
            :image-size="100"
            description="You currently don't have any items in your Shopping Cart." />
      </div>
    </template>
  </div>
</template>

<script>
import ShoppingCartTotal from "../components/ShoppingCartTotal";
export default {
  name: "ListItemsShoppingCart",
  components: {
    ShoppingCartTotal
  },
  computed: {
    ShoppingCartItems() {
      return this.$store.getters["Shop/shoppingCartItems"]
    },
  }
};
</script>

<style lang="scss" scoped>
.list-items-shopping-cart-container {
  max-height: 80vh;
  overflow-y: auto;
  padding: 0;
  background: white;
  border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);

  .empty-shopping-cart-wrapper {
    padding: var(--padding-m);
    background: white;
  }
}
</style>