<template>
  <div class="default-shopping-cart-container">
    <div
        class="shopping-cart-icon-container"
        :class="[{ 'is-active': Quantity > 0 }, {'is-clicked': ShowCart}]"
        @click="updateShowCart">
      <span
          class="el-icon-shopping-cart-1" />

      <template v-if="Quantity > 0">
        <div class="quantity">
          {{ Quantity }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultShoppingCart",
  computed: {
    Quantity() {
      let quantity = 0
      this.ShoppingCartItems.forEach(item => quantity = quantity + item.quantity)
      return quantity
    },
    ShoppingCartItems() {
      return this.$store.getters["Shop/shoppingCartItems"]
    },
    ShowCart() {
      return this.$store.state.Shop.showCart
    }
  },
  methods: {
    updateShowCart() {
      const toggleShowCart = !this.ShowCart

      this.$store.dispatch("Shop/updateShowCart", toggleShowCart)
    }
  }
};
</script>

<style lang="scss" scoped>
.default-shopping-cart-container {
  @media (max-width: 475px) {
    position: absolute;
    top: var(--margin-l);
    right: var(--margin-l);
  }

  .shopping-cart-icon-container {
    width: 34px;
    height: 34px;
    border-radius: var(--border-radius-s);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid white;
    transition: var(--main-transition);

    span {
      color: var(--color-primary);
    }

    &.is-active {
      box-shadow: var(--box-shadow-shortest);
      cursor: pointer;
    }

    &.is-clicked {
      border: 1px solid var(--color-primary);
    }

    .quantity {
      position: absolute;
      background: var(--gradient-primary);
      color: white;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      top: -10px;
      right: -10px;
      font-size: var(--font-size-s)
    }
  }
}
</style>