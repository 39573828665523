<template>
  <component
      :is="renderComponent"
      v-bind="$props" />
</template>

<script>
import { camelCase, startCase } from 'lodash';
import DefaultShoppingCart from "./variants/DefaultShoppingCart";
import ListItemsShoppingCart from "./variants/ListItemsShoppingCart";
import CheckoutItemsShoppingCart from "./variants/CheckoutItemsShoppingCart";
import FloatingShoppingCart from "./variants/FloatingShoppingCart";

export default {
  name: "ShoppingCartBase",
  components: {
    DefaultShoppingCart,
    ListItemsShoppingCart,
    CheckoutItemsShoppingCart,
    FloatingShoppingCart
  },
  props: {
    variant: {
      type: String,
      default: 'default'
    },
  },
  computed: {
    renderComponent() {
      if (this.variant === 'default') {
        return 'DefaultShoppingCart';
      }
      return startCase(camelCase(`${this.variant}ShoppingCart`)).replace(/ /g, '');
    }
  },
  mounted() {
    this.$store.dispatch('Shop/loadShoppingCartFromLocalStorage');
  }
};
</script>