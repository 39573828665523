<template>
  <div
      v-if="IsVisible"
      class="floating-shopping-cart-container"
      :class="windowMode">
    <PortalTarget name="floating-product-details" />
    <div
        v-if="ShoppingCartItems.length"

        class="floating-shopping-cart_inner">
      <div
          v-if="windowMode === 'maximised' || windowMode === 'medium'"
          class="shopping-cart-items-container">
        <transition-group
            name="list"
            tag="div"
            class="transition-group">
          <ShopProductListItemBase
              v-for="(product, index) in ShoppingCartItems"
              :key="`shopping-cart-item-${index}`"
              variant="floating-shopping-cart"
              :quantity="product.quantity"
              :item-index="index"
              :item="product.productDetails" />
        </transition-group>
      </div>

      <div class="checkout-button-container">
        <el-divider
            class="divider"
            direction="vertical" />

        <div class="checkout-button-container">
          <el-button
              type="button"
              class="success"
              size="small"
              @click="handleCheckout">
            Go to Checkout
          </el-button>
        </div>
      </div>
      <div class="window-options">
        <div
            class="minimise window-toggle"
            @click="windowMode = 'minimised'">
          <i class="el-icon-arrow-down" />
        </div>
        <div
            class="medium window-toggle"
            @click="windowMode = 'medium'">
          <i class="el-icon-minus" />
        </div>
        <div
            class="maximise window-toggle"
            @click="windowMode = 'maximised'">
          <i class="el-icon-arrow-up" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FloatingShoppingCart",
  data() {
    return {
      windowMode: "medium",
    }
  },
  computed: {
    ShoppingCartItems() {
      return this.$store.getters["Shop/shoppingCartItems"]
    },
    IsVisible() {
      return !this.$route.path.includes("/checkout");
    }
  },
  methods: {
    handleCheckout() {
      this.$store.dispatch("Shop/updateShowCart", false)
      this.$router.push("/shop/checkout");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "FloatingShoppingCart";
</style>