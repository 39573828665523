import slugify from "slugify";
import Fuse from "fuse.js"
import pos from "pos"

function paginateArray(array, pageSize, pageNumber) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
}

const getters = {
  getTagsFromProductTitle: () => (title) => {
    const words = new pos.Lexer().lex(title);
    const tagger = new pos.Tagger();
    const taggedWords = tagger.tag(words);
    return taggedWords.filter(word => word[1] === "NN").map(word => word[0]);
  },
  getAmountProductsByCategory: (state) => (categoryId) => {
    if (state.products.length && categoryId) {
      const foundProducts = state.products.filter(product => product?.CategoryList?.includes(categoryId))
      return foundProducts.length
    }
    return null
  },
  getProductQuantity: (state) => (productId) => {
    const product = state.products.find(product => product.id === productId)

    let quantity = 0
    if (product) {
      if (product.AvailableSellQuantity) {
        quantity = product.AvailableSellQuantity
      }
      else if (product.WarehouseQuantity.length > 0) {
        const tempQuantity = product.WarehouseQuantity.find(warehouseQuantity => warehouseQuantity.Quantity > 0)
        if (tempQuantity?.Quantity) {
          quantity = parseInt(tempQuantity.Quantity)
        }
      } else {
       if (product.WarehouseQuantity.Quantity > 0) {
         quantity = parseInt(product.WarehouseQuantity.Quantity)
       }
      }
    }
    return quantity
  },
  getPriceCeiling: (state) => {
    if (state.products.length > 0) {
      return Math.max.apply(Math, state.products.map(function(item) { return parseFloat(item.DefaultPrice); }))
    }
    return 3000
  },
  getImageSrc: (state) => (payload) => {
    const basePath = `${state.protocol}${state.imageApiPath}`
    let width = 0;
    let height = 0;

    if (payload.image) {
      if (payload.width) {
        width = payload.width
      }
      if (payload.height) {
        height = payload.height
      }
      return `${basePath}${width}x${height}/media/productImages/${payload.image}`
    }
    return null
  },
  getProductIdFromSlug: () => (productSlug) => {
    let productId = null

    if (productSlug) {
      const match = productSlug.match(/(?:-)(pid\d+)$/)

      if (match.length > 1) {
        if (match[1].includes('pid')) {
          productId = parseInt(match[1].replace('pid', ""))
        }
      }
    }
    return productId
  },
  getProductFromId: (state) => (productId) => {
    return state.products.find(product => parseInt(product.id) === productId)
  },
  getCategories: (state) => {
    const categories = state.categories
    const products = state.products

    categories.forEach(category => {
      category.productCount = 0;
      products.forEach(product => {
        if (product.Categories.length && product.Categories.includes(category.id)) {
          category.productCount++;
        }
      });
    });
    return categories
  },
  getCategory: (state) => (categoryTitle) => {
   return state.categories.find(
        (category) => slugify(category.title.replace('/shop/', '')) === categoryTitle
    );
   },
  getCategoryId: (state) => (categoryTitle) => {
    const category = state.categories.find(
      (category) => slugify(category.title.replace('/shop/', '')) === categoryTitle
    );

    if (category?.id) {
      return category.id;
    }
    return 1;
  },
  getSubCategoryIds: (state) => (categoryId) => {
    const subCategories = state.categories.filter(category => category.parentId === categoryId)
    const subCategoryIdList = subCategories.map(subCategories => subCategories.id)
    const subSubCategories = state.categories.filter(category => subCategoryIdList.includes(category.parentId))
    const subSubCategoriesIdList = subSubCategories.map(subSubCategories => subSubCategories.id)

    return [...subCategoryIdList, ...subSubCategoriesIdList]
  },
  shoppingCartItems: (state) => {
    if (state.products.length > 0) {
      return state.shoppingCart.filter((item) => {
        item.productDetails = state.products.find(product => parseInt(product.id) === item.id)
        if (item.productDetails) {
          return item
        }
      })
    }
    return []

  },
  getMarketingDescription: () => async (productTitle) => {
    const { Configuration,   OpenAIApi } = require("openai");

    const configuration = new Configuration({
      apiKey: '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',
    });
    const openai = new OpenAIApi(configuration);


    const response = await openai.createCompletion({
      model: "text-davinci-002",
      prompt: `Write a marketing description for ${productTitle}`,
      temperature: 0.7,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    });
    return response.data.choices[0].text
  },
  filteredProducts: (state, getters) => (params) => {

    let totalItems = 0;
    let totalPages = 0;
    let products = state.products;
    let keywordSearch = "";
    let showInStockOnly = false;

    // Takes priority over state.filters.keywordSearch
    if (params.filters?.keywordSearch && params.showAt !== 'homepage') {
      keywordSearch = params.filters.keywordSearch
    } else if (state.filters?.keywordSearch) {
      keywordSearch = state.filters.keywordSearch
    }

    // Takes priority over state.filters.showInStockOnly
    if (params.filters?.showInStockOnly != null) {
      showInStockOnly = params.filters.showInStockOnly
    } else if (state.filters?.showInStockOnly) {
      showInStockOnly = state.filters.showInStockOnly
    }

    if (products.length && !state.isLoadingAllData) {

      if (keywordSearch.length > 2 && params.showAt !== 'homepage') {

        // Fuse.js Search Plugin
        const options = {
          includeScore: true,
          includeMatches: true,
          ignoreLocation: true,
          minMatchCharLength: 3,
          threshold: 0.02,
          useExtendedSearch: true,
          keys: [
            {
              name: "Name",
              weight: 3
            },
            {
              name: "Description",
              weight: 1,
            },
            {
              name: "SKU",
              weight: 1,
            },

          ]
        }

        const fuse = new Fuse(products, options)
        const searchResult = fuse.search(keywordSearch)
        const filteredSearchResult = searchResult.filter(searchResult => searchResult.score < 0.85)

        products = filteredSearchResult.map(result => result.item)
      }

      if (state.filters.categoryTitle &&
        state.filters.categoryTitle.toLowerCase() !== "all-products" ) {
        const categoryId = getters['getCategoryId'](state.filters.categoryTitle);
        const subCategoryIds = getters['getSubCategoryIds'](categoryId)
        const filterCategories = [categoryId, ...subCategoryIds]


        if (state.filters.keywordSearch?.length > 2) {
          products = products.filter(
            (product) => {
              if (product.Categories.length) {
                return product.Categories.find(categoryId => filterCategories.includes(categoryId))
              }
            }
          );
        } else {
          products = state.products.filter(
            (product) => {
              if (product.Categories.length) {
                return product.Categories?.find(categoryId => filterCategories.includes(categoryId))
              }
            }
          );
        }

      }
      if (state.filters.priceRange) {
        products = products.filter(
          (product) =>
            parseFloat(product.DefaultPrice) >=
            state.filters.priceRange.minValue &&
            parseFloat(product.DefaultPrice) <= state.filters.priceRange.maxValue
        );
      }

      if (state.filters.sortOrder) {
        //@todo add sorting algorithm here
      }

      if (showInStockOnly) {
        products = products.filter(product => {
            return product.AvailableSellQuantity > 0
        })
      }

      // Sorting Products
      if(keywordSearch?.length <= 2) {
        if (state.filters.sortOrder === 'dateNew') {
          products.sort(function(a, b) {
            return new Date(b.DateUpdated) - new Date(a.DateUpdated);
          });
        }

        if (state.filters.sortOrder === 'dateOld') {
          products.sort(function(a, b) {
            return new Date(a.DateUpdated) - new Date(b.DateUpdated);
          });
        }

        if (state.filters.sortOrder === 'priceHigh') {
          products.sort(function(a, b) {
            return parseFloat(b.DefaultPrice) - parseFloat(a.DefaultPrice);
          });
        }

        if (state.filters.sortOrder === 'priceLow') {
          products.sort(function(a, b) {
            return parseFloat(a.DefaultPrice) - parseFloat(b.DefaultPrice);
          });
        }
      }

      if (params.order) {
        if (params.order === 'desc') {
          // order products by dateUpdated
          products = products.sort(function(a, b) {
            return new Date(b.DateUpdated) - new Date(a.DateUpdated);
          });

          products = products.filter(product => product.AvailableSellQuantity > 0)
        }
      }

      if (products.length === 0) {
        products = null
      } else {

        if (params && params.perPage && params.page) {
          totalItems = products.length

          if (totalItems > params.perPage) {
            totalPages = Math.floor(totalItems / params.perPage)
          }

          products = paginateArray(products, params.perPage, params.page)
        }
      }

      return {
        products: products,
        totalPages: totalPages,
        totalItems: totalItems
      };
    }
    return {
      products: [],
      totalPages: 0,
      totalItems: 0
    };
  }
};

export default getters