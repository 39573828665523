<template>
  <div class="product-list-container">
    <component
        :is="renderComponent"
        v-bind="$props" />
  </div>
</template>

<script>
import DefaultProductList from "./variants/DefaultProductList";
import { camelCase, startCase } from 'lodash';

export default {
  name: "ProductListBase",
  components: {
    DefaultProductList
  },
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    items: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: [Number, Boolean],
      default: false
    },
    selectedItem: {
      type: Number,
      default: null
    }
  },
  computed: {
    renderComponent() {
      if (this.variant === 'default') {
        return 'DefaultProductList';
      }
      return startCase(camelCase(`${this.variant}ProductList`)).replace(/ /g, '');
    }
  }
};
</script>