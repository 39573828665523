import axios from "axios";

function getProtocol() {
  return `${location.protocol}//`
}

const actions = {
  updateIsLoadingAllData({commit}, payload) {
    commit("UPDATE_IS_LOADING_ALL_DATA", payload)
  },
  getProtocol({ commit }) {
    const protocol = getProtocol()
    commit('UPDATE_PROTOCOL', protocol)
  },
  resetShippingQuotes({commit}) {
    commit("UPDATE_SELECTED_SHIPPING_QUOTE", {})
    commit('UPDATE_SHIPPING_QUOTES', []);
  },
  // async createOrder({ commit, state }) {
  //   //fetch order data from state
  //   const order = state.order;
  //
  //   //try async creating order from server with axios post request
  //
  //   // const createOrderResponse = await axios.post(`${state.protocol}${state.host}/api/createOrder`, order);
  //   const response = await axios.post(`${state.protocol}${state.host}/api/orders`, order);
  //     //if order was created successfully, update order with new status and id
  //     if (response.status === 201) {
  //       commit("UPDATE_ORDER", {
  //         ...order,
  //         id: response.data.id,
  //         status: response.data.status
  //       });
  //     }
  //
  //
  //
  // },
  async fetchShippingQuote({ commit, dispatch, getters, state}, payload) {
    let shoppingCartItems

    if (payload && payload.items) {
      shoppingCartItems = payload.items
    } else {
      shoppingCartItems = getters['shoppingCartItems']
    }

    dispatch("resetShippingQuotes")
    commit('UPDATE_IS_UPDATING_SHIPPING_QUOTE', true)
    try {

      const orderLines = shoppingCartItems.map(item => (
          {
            SKU: item.productDetails.SKU,
            Quantity: item.quantity,
            UnitPrice: item.productDetails.DefaultPrice
          }
        )
      )

      let addressObject
      if (state.checkout.orderDetails.shipping === "ship_to_shipping_address") {
        addressObject = state.checkout.shippingAddress
      } else {
        addressObject = state.checkout.billingAddress
      }

      let postCode = ""
      let addressState = ""
      let country = ""
      let city = ""

      if (addressObject) {
        postCode = addressObject.postcode
        addressState = addressObject.state
        country = addressObject.country
        city = addressObject.city
      }

      const payload = {
        ShippingQuote: {
          ShipPostCode: postCode,
          ShipCountry: country,
          ShipState: addressState,
          ShipCity: city,
          ShipPOBox: "false",
          TaxInclusive: "true",
          OrderLines: {
            OrderLine: orderLines
          }
        }
      };

      const response = await axios.post(`${getProtocol()}${state.api}/api/quotes/getShippingQuote`, payload);
      if (response.data.data.ShippingQuotes.ShippingQuote) {

        let pickup = "false";
        if (state.checkout.orderDetails.shipping === "pick-up") {
          pickup = "true"
        }
        const filteredShippingQuotes = response.data.data.ShippingQuotes.ShippingQuote.filter(quote => quote.PickUp === pickup)
        commit('UPDATE_SHIPPING_QUOTES', filteredShippingQuotes);
        if (pickup === "true") {
          commit("UPDATE_SELECTED_SHIPPING_QUOTE", filteredShippingQuotes[1])
        }
      }


    } catch (error) {
      //eslint-disable-next-line
      console.warn("error", error)
    }
    commit('UPDATE_IS_UPDATING_SHIPPING_QUOTE', false)
  },
  async fetchProduct({ commit, state }, itemId) {
    const payload = {
      itemId: itemId,
    }

    const products = state.products
    if(state.products.filter(product => parseInt(product.id) === parseInt(itemId)).length === 0) {
      try {
        const response = await axios.post(`${getProtocol()}${state.api}/api/products/get/`, payload);
        const product = response.data.data.data

        products.push(product)
        commit("UPDATE_PRODUCTS", products)

      } catch (error) {
        //eslint-disable-next-line
        console.warn("error", error)
      }
    }
  },
  async fetchProducts({ commit, state }) {
    try {
      // const timestamp = Date.now() / 1000;
      const response = await axios.get(`${getProtocol()}${state.api}/api/products/list/`, {
        headers: {
          'Authorization': `Bearer: hear-goes-my-auth-token`
        }
      });

      if (response.data.data.data) {
        commit('UPDATE_PRODUCTS', response.data.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async fetchCategories_old({ commit, state }) {
    try {
      const response = await axios.get(`${state.protocol}${state.api}/mock/mockCategories.json`);
      commit('UPDATE_CATEGORIES', response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchCategories({ commit, state }, payload) {
    let perPage = 999
    let page = 1

    if (payload?.perPage) {
      perPage = payload.perPage
    }

    if (payload?.page) {
      page = payload.page
    }

    try {
      const response = await axios.get(`${state.protocol}${state.api}/api/shopCategories/list/?per_page=${perPage}&page=${page}`);
      commit('UPDATE_CATEGORIES', response.data.data.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchProductImages() {
    // try {
    //   const timestamp = Date.now() / 1000;
    //   const response = await axios.get(`${state.protocol}${state.api}/media/product_images.json?time=${timestamp}`);
    //   const productImages = response.data
    //   const newProducts = JSON.parse(JSON.stringify(state.products))
    //
    //   newProducts.map(newProduct => {
    //     const foundProductImages = productImages.find(productImage => productImage.id === newProduct.id)
    //     if (foundProductImages && foundProductImages.images.length) {
    //
    //       // const updatedProductImages = foundProductImages.images.map(productImage =>
    //       //   `${state.protocol}${state.imageApiPath}${productImage}`
    //       // )
    //
    //       newProduct.ImageList = foundProductImages.images
    //     }
    //   })
    //
    //
    //   commit('UPDATE_PRODUCTS',newProducts);
    // } catch (error) {
    //   console.error(error);
    // }
  },
  async fetchProductCategoryMapping() {
    // try {
    //   const timestamp = Date.now() / 1000;
    //
    //   // const response = await axios.get(`${getProtocol()}${state.api}/media/product_categories.json`);
    //   const response = await axios.get(`${state.protocol}${state.api}/api/shopCategories/getCategoryMapping?time=${timestamp}`)
    //
    //   const productCategories = response.data.data
    //   const newProducts = JSON.parse(JSON.stringify(state.products))
    //
    //   const mutatedProducts = newProducts.filter(newProduct => {
    //     const foundProductCategories = productCategories.find(productCategory => productCategory.productId === newProduct.ID)
    //     if (foundProductCategories) {
    //       newProduct.CategoryList = foundProductCategories.categoryIds
    //       return newProduct
    //     }
    //   })
    //
    //   commit('UPDATE_PRODUCTS',mutatedProducts);
    // } catch (error) {
    //   console.error(error);
    // }
  },
  resetCheckout({ state, commit }) {
    const checkout = JSON.parse(JSON.stringify(state.checkout))
    checkout.orderDetails.status = null
    checkout.orderDetails.verified = null
    commit('UPDATE_CHECKOUT_DETAILS', checkout)
  },
  updateProducts({ commit }, payload) {
    commit('UPDATE_PRODUCTS', payload);
  },
  updateCategories({ commit }, payload) {
    commit('UPDATE_CATEGORIES', payload);
  },
  updateFilters({ state, commit }, payload) {
    const newFilters = payload;
    const currentFilters = JSON.parse(JSON.stringify(state.filters));

    currentFilters[newFilters.type] = newFilters.values;

    commit('UPDATE_FILTERS', currentFilters);
  },
  updateShoppingCartQuantity({ commit, state, dispatch }, payload) {
    dispatch('resetCheckout')

    const mutatedCartItems = JSON.parse(JSON.stringify(state.shoppingCart));

    mutatedCartItems.find((item) => {
      if (parseInt(item.id) === parseInt(payload.itemId)) {
        if (payload.quantity !== 0) {
          item.quantity = payload.quantity;
        }
      }
    });

    commit('UPDATE_SHOPPING_CART', mutatedCartItems);
  },
  addToShoppingCart({ commit, state, dispatch }, payload) {
    dispatch('resetCheckout')

    const mutatedCartItems = JSON.parse(JSON.stringify(state.shoppingCart));
    const productId = payload.id;
    const productQuantity = payload.quantity;
    let itemFound = false;

    mutatedCartItems.forEach((item) => {
      if (item.id === productId) {
        item.quantity = item.quantity + productQuantity;
        itemFound = true;
      }
    });

    if (!itemFound) {
      mutatedCartItems.push(payload);
    }

    commit('UPDATE_SHOPPING_CART', mutatedCartItems);
  },
  loadShoppingCartFromLocalStorage({ commit }) {
    if (localStorage.getItem('shoppingCart')) {
      const shoppingCart = JSON.parse(localStorage.getItem('shoppingCart'));
      commit('UPDATE_SHOPPING_CART', shoppingCart);
    }
  },
  updateShowCart({ commit }, payload) {
    commit('UPDATE_SHOW_CART', payload);
  },
  removeItemFromCart({ commit, state, dispatch }, payload) {
    dispatch('resetCheckout')

    const mutatedCartItems = JSON.parse(JSON.stringify(state.shoppingCart));
    mutatedCartItems.splice(payload, 1);

    commit('UPDATE_SHOPPING_CART', mutatedCartItems);
  },
  updateAddress({ commit }, payload) {
    if (payload.type === "billingAddress") {
      commit('UPDATE_BILLING_ADDRESS', payload.values);
    } else if (payload.type === "shippingAddress") {
      commit('UPDATE_SHIPPING_ADDRESS', payload.values);
    }
  },
  updatePaymentOption({commit}, payload) {
    commit('UPDATE_SELECTED_PAYMENT', payload);
  },
  updateCheckoutDetails({ commit, state }, payload) {
    // dispatch("resetShippingQuotes")
    const checkout = JSON.parse(JSON.stringify(state.checkout))
    checkout[payload.type] = payload.values
    commit('UPDATE_CHECKOUT_DETAILS', checkout)
  },
  updateSelectedShippingQuote({commit}, payload) {
    commit("UPDATE_SELECTED_SHIPPING_QUOTE", payload)
  },
  async fetchOrderDetails({ commit, state }, payload) {
    try {
      const response = await axios.post(`${state.protocol}${state.api}/api/orders/get/`, { orderID: payload });
      commit("UPDATE_VERIFIED_ORDER_DETAILS", response.data.data.Order[0]);
    }catch (error) {
      console.error(error);
    }
  },
  async checkOrderPaypalPaymentStatus({ commit, state }, payload) {
    try {
      const response = await axios.post(`${state.protocol}${state.api}/api/orders/checkOrderPaypalPaymentStatus/`, payload);

      if(response.data.success) {
        commit('UPDATE_SHOPPING_CART', []);

        const newCheckoutDetails = JSON.parse(JSON.stringify(state.checkout))
        newCheckoutDetails.orderDetails.status = 'completed'

        commit('UPDATE_CHECKOUT_DETAILS', newCheckoutDetails);
      }


    }catch (error) {
      console.error(error);
    }
  },
  async updateOrderPaypalOrderID({ commit, state }, payload) {
    try {
      const newVerifiedDetails = JSON.parse(JSON.stringify(state.checkout.orderDetails.verified))
      const response = await axios.post(`${state.protocol}${state.api}/api/orders/updatePaypalOrderID/`, payload);

      if (response.success) {
        newVerifiedDetails.paypalOrderID = payload.paypalOrderID

        commit("UPDATE_VERIFIED_ORDER_DETAILS", newVerifiedDetails);
      }
    }catch (error) {
      console.error(error);
    }
  },

  async createOrder({ commit, dispatch, state, getters }) {

    // create formatted datetime string gmt
    const date = new Date();
    const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    let billingCountry = state.checkout.billingAddress.country
    if (state.checkout.billingAddress.country === 'AU') {
      billingCountry = "Australia"
    }

   let shippingCountry = state.checkout.shippingAddress.country
    if (state.checkout.shippingAddress.country === 'AU') {
      shippingCountry = "Australia"
    }

    try {
      let shippingMethod = state.checkout.orderDetails.shipping

      if (state.selectedShippingQuote && state.selectedShippingQuote.ShippingMethod.Name) {
        shippingMethod = state.selectedShippingQuote.ShippingMethod.Name
      }

      const order = {
        Username: 'test',
        OrderType: 'quote',
        OnHoldType: "Enumeration",
        DatePlaced: dateString,
        CustomerEmail: state.checkout.billingDetails.email,
        BillFirstName: state.checkout.billingDetails.firstname,
        BillLastName: state.checkout.billingDetails.lastname,
        BillStreet1: state.checkout.billingAddress.street,
        BillCity: state.checkout.billingAddress.suburb,
        BillState: state.checkout.billingAddress.state,
        BillPostCode: state.checkout.billingAddress.postcode,
        BillContactPhone: state.checkout.billingDetails.phone,
        BillCountry: billingCountry,
        ShipFirstName: state.checkout.shippingDetails.firstname,
        ShipLastName: state.checkout.shippingDetails.lastname,
        ShipStreet1: state.checkout.shippingAddress.street,
        ShipCity: state.checkout.shippingAddress.suburb,
        ShipState: state.checkout.shippingAddress.state,
        ShipPostCode: state.checkout.shippingAddress.postcode,
        ShipContactPhone: state.checkout.shippingDetails.phone,
        ShipCountry: shippingCountry,
        EnableAddressValidation: false,
        SalesChannel: "canetoadequip",
        ShippingMethod: shippingMethod,
        // ShippingCost: state.selectedShippingQuote.ShippingCost,
        ShippingDiscount: "0",
        SignatureRequired: false,
        CurrencyCode: "AUD",
        OrderLine: [],
        OrderRounding: "Decimal"
      }
      if (state.checkout.orderDetails.shipping === "ship_to_billing_address") {
        order.ShipFirstName = state.checkout.billingDetails.firstname;
        order.ShipLastName = state.checkout.billingDetails.lastname;
        order.ShipStreet1 = state.checkout.billingAddress.street;
        order.ShipCity = state.checkout.billingAddress.suburb;
        order.ShipState = state.checkout.billingAddress.state;
        order.ShipPostCode = state.checkout.billingAddress.postcode;
        order.ShipContactPhone = state.checkout.billingDetails.phone;
        order.ShipCountry = billingCountry;
      }

      order.OrderLine = getters["shoppingCartItems"].map(item => (
        {
        SKU: item.productDetails.SKU,
        ItemDescription: item.productDetails.Name,
        Quantity: item.quantity,
        // UnitPrice: item.productDetails.DefaultPrice,
        // UnitCost: item.productDetails.CostPrice,
        QuantityShipped: item.quantity
      }));

      const payload = {
        Order: order
      }

      const response = await axios.post(`${state.protocol}${state.api}/api/orders/create`, payload);

      if (response.data.success) {
        commit('UPDATE_ORDER_ID', response.data.data.Order.OrderID);
        commit('UPDATE_ORDER_STATUS', 'created')

        dispatch('fetchOrderDetails', response.data.data.Order.OrderID)
      }
    } catch (error) {
      //eslint-disable-next-line
      console.log("error", error)
    }
  }
};

export default actions