import Vue from 'vue';
import VueRouter from 'vue-router';
import modules from '@/modules';
import DefaultView from '@/views/DefaultView';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    registerChildren: true,
    children: modules,
    redirect: {
      name: 'home-view'
    },
    component: DefaultView
  },
  {
    path: '/about',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
];


const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()

})

export default router;
