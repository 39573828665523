<template>
  <div class="product-gallery-container limited-width-content">
    <h2>Check out Similar Products</h2>
    <div
        v-if="products.length"
        class="product-gallery__inner">
      <ProductListItemBase
          v-for="(product, index) in products"
          :key="`similar-product-${index}`"
          :item="product" />
    </div>
  </div>
</template>

<script>
import ProductListItemBase from "@/Modules/Shop/components/ProductListItem/ProductListItemBase";
export default {
  name: "ProductGallery",
  components: { ProductListItemBase },
  props: {
    filter: {
      type: Object,
      default: () => {}
    },
    limit: {
      type: Number,
      default: 10
    },
    products: {
      type: Array,
      required: true
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./ProductGallery.scss";

::-webkit-scrollbar {
  background-color: transparent;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--gradient-primary);
  border-radius: var(--border-radius-s);
}
</style>