<template>
  <div class="default-product-list">
    <template v-if="categoryDescription">
      <div
          class="category-header">
        <div
            class="category-image"
            :style="`background-image: url(${categoryImage})`" />
        <div class="category-description">
          <div class="category-description__inner">
            <h2>{{ categoryTitle }}</h2>
            <div v-html="categoryDescription" />
            <el-tag
                v-for="(tag, index) in categoryTags"
                :key="`category-tag-${index}`"
                type="info">
              {{ tag }}
            </el-tag>
          </div>
        </div>
      </div>
    </template>
    
    <template v-if="items === null">
      <div class="empty-message-container">
        <template v-if="Keyword">
          <el-empty
              description="No Search Results">
            We couldn't find any products for this category matching your search <span class="keyword">{{ Keyword }}</span>
          </el-empty>
        </template>
        <template v-else>
          <el-empty description="We couldn't find any products for this category." />
        </template>
        <router-link
            class="el-button--primary"
            tag="button"
            to="/shop/">
          Back To Home
        </router-link>
      </div>
    </template>
    <template v-else-if="items.length">
      <transition-group
          name="list"
          class="default-product-list">
        <ProductListItemBase
            v-for="(item, index) in computedItems"
            :key="`product-list-item-${index}`"
            :variant="productListItemVariant"
            :selected="selectedItem === parseInt(item.id)"
            :item="item" />
      </transition-group>
    </template>
  </div>
</template>

<script>
import ProductListItemBase from "@/Modules/Shop/components/ProductListItem/ProductListItemBase";
export default {
  name: "DefaultProductList",
  components: { ProductListItemBase },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    limit: {
      type: [Number, Boolean],
      default: false
    },
    productListItemVariant: {
      type: String,
      default: "default"
    },
    selectedItem: {
      type: Number,
      default: null
    }
  },
  computed: {
    Category() {
      return this.$store.getters['Shop/getCategory'](this.$route.params.category);
    },
    categoryTitle() {
      return this.Category?.title || null
    },
    categoryTags() {
      const tags = this.Category?.tags || null
      if (tags) {
        return tags
      }
      return []
    },
    categoryDescription() {
        const description = this.Category?.description || null

      // remove all <br> tags
      if (description) {
        return description.replace(/<br>/g, "")
      }
      return null
    },
    categoryImage() {
      const image = this.Category?.backgroundImage || null
      if (image) {
        return this.$store.getters["Shop/getImageSrc"]({image: image, width: 0, height: 400})
      }
      return "https://via.placeholder.com/150"
    },
    Keyword() {
      return this.$store.state.Shop.filters.keywordSearch
    },
    computedItems() {
      let items = this.items
      if (this.limit) {
        items = this.items.slice(0,this.limit)
      }
      return items
    }
  }
};
</script>

<style lang="scss" scoped>
.default-product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;

  .keyword {
    background: var(--color-primary-lighter-background);
    padding: var(--padding-s) var(--padding-m);
    border-radius: var(--border-radius-s);
  }

  @media (max-width: 475px) {
    gap: 20px 4%;
    flex-wrap: wrap;
  }

  .empty-message-container {
    background: white;
    border-radius: var(--border-radius-m);
    padding: var(--padding-m);
    width: 100%;
    text-align: center;
  }

  .category-header {
    min-width: 100%;
    background: var(--color-tertiary-background);
    border-radius: var(--border-radius-m);
    display: flex;
    align-items: center;
    gap: 1rem;
    overflow: hidden;

    .category-image {
      flex: 1;
      min-width: 40%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .category-description {
      padding: 0 var(--padding-l) var(--padding-l) var(--padding-m);
      width: 100%;

      &__inner {
        max-width: 50rem;
        justify-content: left;
      }


      ::v-deep {
        p, li {
        line-height: 1.5;
        font-size: var(--font-size-l);
      }
    }

      h2 {
        font-weight: 600;
      }

      ::v-deep .el-tag.el-tag--info {
        color: var(--color-primary-link);
        background-color: var(--color-secondary-background);
        border-radius: var(--border-radius-l);
        box-shadow: var(--box-shadow-short);
        border: 0;
        margin: var(--margin-xs);
      }
    }
  }


}
</style>