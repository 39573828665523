<template>
  <div class="add-to-cart-base-container">
    <component
        :is="renderComponent"
        v-bind="$props"
        v-on="$listeners" />
  </div>
</template>

<script>
import DefaultAddToCart from "./variants/DefaultAddToCart";
import ProductDetailsAddToCart from "./variants/ProductDetailsAddToCart";
import { camelCase, startCase } from 'lodash';

export default {
  name: "AddToCartBase",
  components: {
    DefaultAddToCart,
    ProductDetailsAddToCart
  },
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    item: {
      type: Object,
      required: true
    },
    showQuantitySelector: {
      type: Boolean,
      default: false
    },
    stockStatus: {
      type: [Object, Array, Number, String],
      default: 0
    },
    size: {
      type: String,
      default: "normal"
    }
  },
  computed: {
    renderComponent() {
      if (this.variant === 'default') {
        return 'DefaultAddToCart';
      }
      return startCase(camelCase(`${this.variant}AddToCart`)).replace(/ /g, '');
    }
  }
};
</script>
