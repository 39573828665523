import { apiSettings, bankTransferDetails } from "@/Modules/Shop/shopSettings";

const state = () => {
  return {
    api: apiSettings.api,
    imageApiPath: apiSettings.imageApiPath,
    paypalApiClientId: apiSettings.paypalApiClientId,
    bankTransferDetails: bankTransferDetails,
    categories: [],
    products: [],
    shoppingCart: [],
    shippingQuotes: [],
    selectedShippingQuote: null,
    filters: {
      sortOrder: "dateNew",
      showInStockOnly: true,
      searchKeyword: ""
    },
    showCart: false,
    protocol: "http",
    isLoadingAllData: false,
    isUpdatingShippingQuote: false,
    checkout: {
      billingDetails: {
        firstname: null,
        lastname: null,
        email: null,
        company: null,
        phone: null
      },
      billingAddress: {
        city: null,
        country: null,
        state: null,
        street: null,
        suburb: null,
        postcode: null,
        deliveryNotes: null
      },
      shippingDetails: {
        firstname: null,
        lastname: null,
        company: null,
        phone: null,
        email: null
      },
      shippingAddress: {
        city: null,
        country: null,
        state: null,
        street: null,
        suburb: null,
        postcode: null,
        deliveryNotes: null
      },
      orderDetails: {
        voucher: null,
        shipping: null,
        payment: null,
        status: null,
        orderId: null,
        verified: null
      }
    }
  };
};

export default state