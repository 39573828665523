<template>
  <div class="default-product-details-container">
    wkqwejkqwejk
  </div>
</template>

<script>
export default {
  name: "DefaultProductDetails"
};
</script>

<style scoped>

</style>