<template>
  <div class="checkout-product-list-item-container">
    <div class="product-container__outer">
      <div class="product-container__inner">
        <div class="image-wrapper">
          <CoreImageBase
              class="product-image-wrapper"
              :image-src="ImageSrc"
              :alt="`${Title}`" />
        </div>
        <div class="description-container">
          <div class="product-title">
            <h1>{{ Title }}</h1>
          </div>
          <div class="delete-item-container">
            <span
                class="delete-item"
                @click="deleteItem"><i class="el-icon-delete" /></span>
          </div>
        </div>
      </div>
    </div>
    <div class="product-footer">
      <div
          v-if="1 === 2"
          class="stock-status"
          :class="StockStatus.class">
        {{ StockStatus.title }}
      </div>
      <div class="price-container">
        <div class="product-quantity">
          <el-input-number
              v-model="Quantity"
              class="product-quantity-element"
              size="mini"
              controls-position="right"
              :min="1"
              :max="100" />
        </div>

        <div class="item-price">
          <div class="item-price-title">
            $ per item
          </div>
          <ShopProductPrice
              :sell-price="DefaultPrice"
              :rrp-price="RRPPrice" />
        </div>
        <div class="item-subtotal">
          <div class="item-price">
            <div
                class="item-price-title">
              Subtotal
            </div>
            <ShopProductPrice
                :sell-price="SubtotalDefaultPrice"
                :rrp-price="SubtotalRRPPrice" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutProductListItem",
  props: {
    itemIndex: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true
    },
    quantity: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    ImageSrc() {
      if (this.item.ImageList?.length > 0) {
        return this.$store.getters["Shop/getImageSrc"]({image: this.item.ImageList[1], width: 100, height: 100})
      }
      return "https://via.placeholder.com/150"
    },
    Title() {
      return this.item.Name
    },
    Category() {
      if (this.item.Categories?.length > 0) {
        return this.item.Categories[0].Category.CategoryName
      }
      return false
    },
    DefaultPrice() {
      if (this.item.DefaultPrice) {
        return this.item.DefaultPrice
      }
      return 'N/A'
    },
    Quantity: {
      get() {
        return this.quantity
      },
      set(val) {
        this.$store.dispatch("Shop/updateShoppingCartQuantity", { itemId: this.item.id, quantity: val})
      }
    },
    StockStatus() {
      return {
        class: "in-stock",
        title: "in stock"
      }
    },
    SubtotalDefaultPrice() {
      return this.item.DefaultPrice * this.quantity
    },
    SubtotalRRPPrice() {
      return this.RRPPrice * this.quantity
    },
    RRPPrice() {
      if (this.item.RRP) {
        if (!isNaN(this.DefaultPrice) && this.DefaultPrice < this.item.RRP) {
          return this.item.RRP
        }
      }
      return null
    }
  },
  methods: {
    deleteItem() {
      this.$store.dispatch("Shop/removeItemFromCart", this.itemIndex)
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'CheckoutProductListItem.scss';
</style>