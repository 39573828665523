<template>
  <div class="default-category-list-item-container">
    <img
        v-if="item.background"
        class="background-effect"
        :alt="item.title"
        :src="require(`@/assets/img/${item.background}`)">

    <router-link
        class="image-container"
        :to="item.url">
      <img
          :src="require(`@/assets/img/${item.image}`)"
          :alt="item.title">
    </router-link>
    <router-link
        :to="item.url"
        class="title-container">
      <h2>{{ item.title }}</h2>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "DefaultCategoryListItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.default-category-list-item-container {
  background: white;
  border-radius: var(--border-radius-m);
  display: flex;
  align-items: center;
  width: calc(50% - 20px);
  padding: var(--padding-m);
  box-sizing: border-box;
  transition: var(--main-transition);
  cursor: pointer;
  position: relative;
  overflow: hidden;


  .background-effect {
    position: absolute;
    transition: var(--main-transition);
    transform: translateY(180px) translateX(120px);
    opacity: 0.4;
    max-width: 200px;
  }

  &:hover {
    .background-effect {
      transform: translateY(30px) translateX(70px);

    }
  }

  .title-container {
    flex: 1;
    z-index: 1;

    h2 {
      font-size: var(--font-size-l);
      font-weight: 600;
    }
  }
  .image-container {
    flex: 1;
    z-index: 1;

    img {
      width: 100%;


      max-width: 70px;
    }
  }

  &:hover {
    box-shadow: var(--box-shadow-shortest);

    .title-container {
      h2 {
        color: var(--color-primary);
      }
    }
  }
}
</style>