<template>
  <component
      :is="renderComponent"
      v-bind="$props" />
</template>

<script>
import { camelCase, startCase } from 'lodash';
import DefaultProductListItem from "./variants/DefaultProductListItem";
import ShoppingCartProductListItem
  from "./variants/ShoppingCartProductListItem";
import CheckoutProductListItem from "@/Modules/Shop/components/ProductListItem/variants/CheckoutProductListItem";
import FloatingShoppingCartProductListItem
  from "./variants/FloatingShoppingCartProductListItem";
import SkeletonProductListItem from "./variants/SkeletonProductListItem";

export default {
  name: "ProductListItemBase",
  components: {
    DefaultProductListItem,
    ShoppingCartProductListItem,
    CheckoutProductListItem,
    FloatingShoppingCartProductListItem,
    SkeletonProductListItem
  },
  props: {
    variant: {
      type: String,
      default: 'default'
    },
    productListItemVariant: {
      type: String,
      default: "default"
    },
    item: {
      type: Object,
      required: true
    },
    itemIndex: {
      type: Number,
      default: 0
    },
    quantity: {
      type: [String, Number],
      default: null
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    renderComponent() {
      if (this.variant === 'default') {
        return 'DefaultProductListItem';
      }
      return startCase(camelCase(`${this.variant}ProductListItem`)).replace(/ /g, '');
    }
  },
};
</script>